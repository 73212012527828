import { CircularProgress, styled } from '@mui/material';

const OuterLoading = styled('div')({
    background: '#fff',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
});

export const Loading = () => (
    <OuterLoading>
        <CircularProgress size='5rem' />
    </OuterLoading>
);
