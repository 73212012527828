import { AxiosInstance } from "axios";

import { LoginResponse } from "types";

export class AccountService {
    constructor(private httpClient: AxiosInstance) {}

    public login = async (): Promise<LoginResponse> => {
        const response = await this.httpClient.get<LoginResponse>(
            "/api/account/login"
        );
        return response?.data;
    };

    public signIn = async (
        userName: string,
        password: string
    ): Promise<boolean> => {
        const response = await this.httpClient.post("/api/account/login", {
            userName: userName,
            password: password,
        });
        return response.status === 200;
    };

    public logout = async (): Promise<boolean> => {
        const response = await this.httpClient.get("/api/account/logout");

        return response.status === 200;
    };

    public register = async (
        userName: string,
        password: string
    ): Promise<boolean> => {
        const response = await this.httpClient.post("/api/account/register", {
            userName: userName,
            password: password,
        });

        return response.status === 200;
    };

    public updatePassword = async (
        oldPassword: string,
        newPassword: string
    ) => {
        const response = await this.httpClient.post(
            "/api/account/update-password",
            {
                oldPassword: oldPassword,
                newPassword: newPassword,
            }
        );

        return response.status === 200;
    };
}
