import { RouteObject } from "react-router-dom";

import { Layout } from "components";
import {
    SeriesPage,
    WatchPage,
    LoginPage,
    LogoutPage,
    RegisterPage,
    UpdatePasswordPage,
} from "pages";

const routes: RouteObject[] = [
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                path: "/watch/:contentName/:seriesNumber",
                element: <WatchPage />,
            },
            {
                path: "/series/:contentName",
                element: <SeriesPage />,
            },
            {
                path: "/Account/Password",
                element: <UpdatePasswordPage />,
            },
        ],
    },
    {
        path: "/Account/Login",
        element: <LoginPage />,
    },
    {
        path: "/Account/Logout",
        element: <LogoutPage />,
    },
    {
        path: "/Account/Register",
        element: <RegisterPage />,
    },
];

export default routes;
