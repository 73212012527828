import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Typography,
} from "@mui/material";
import { observer } from "mobx-react";
import { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRef } from "react";
import ReactPlayer from "react-player/lazy";
import screenfull from "screenfull";

import { useStores } from "hooks";
import { getStreamUrl } from "helpers";

export const WatchPage = observer(() => {
    const navigate = useNavigate();
    const { contentName, seriesNumber } = useParams();
    const { watchStore } = useStores();
    const player = useRef<ReactPlayer>(null);

    if (!contentName || !seriesNumber) {
        return null;
    }

    const [isInitialized, setIsInitialized] = useState(
        watchStore.isReady(contentName, seriesNumber)
    );

    const streamUrl = getStreamUrl(contentName, seriesNumber);

    const { availableSeries, skipSettings, userInfo } = watchStore;

    useEffect(() => {
        async function init() {
            if (!contentName || !seriesNumber) {
                return;
            }

            const task1 = watchStore.getAvailableSeries(contentName);
            const task2 = watchStore.getSkipSettings(contentName, seriesNumber);
            const task3 = watchStore.getUserInfo();

            await Promise.all([task1, task2, task3]);

            if (contentName in availableSeries) {
                setIsInitialized(true);
            }
        }

        init();
    }, [contentName, watchStore, seriesNumber, availableSeries]);

    const handleKeyPress = useCallback(
        (event: KeyboardEvent) => {
            if (
                event.code === "KeyF" &&
                document.fullscreenElement === null &&
                player
            ) {
                screenfull.request(
                    (player.current as any).player.player.player
                );
            }
        },
        [player]
    );

    useEffect(() => {
        window.addEventListener("keydown", handleKeyPress);

        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    }, [handleKeyPress]);

    const onNext = () => {
        navigate(`/watch/${contentName}/${Number(seriesNumber) + 1}`);
    };

    const onPrevious = () => {
        navigate(`/watch/${contentName}/${Number(seriesNumber) - 1}`);
    };

    return isInitialized ? (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "50px",
                padding: "25px 0px 25px 0px",
            }}
        >
            <Box>
                <Typography variant="h4">
                    {`Series ${seriesNumber} - ${watchStore.getSeriesName(
                        contentName,
                        seriesNumber
                    )}`}
                </Typography>
            </Box>

            <Box sx={{ width: "100%" }}>
                <ReactPlayer
                    width="100%"
                    height="100%"
                    ref={player}
                    playing={true}
                    controls={true}
                    volume={1}
                    muted={false}
                    url={streamUrl}
                    onProgress={(state) => {
                        if (!player || !player.current) {
                            return;
                        }

                        if (userInfo.isSkipIntro) {
                            if (
                                state.playedSeconds <=
                                    skipSettings.skipIntroEndTime &&
                                state.playedSeconds >=
                                    skipSettings.skipIntroStartTime
                            ) {
                                if (skipSettings.skipIntroEndTime) {
                                    player.current.seekTo(
                                        skipSettings.skipIntroEndTime,
                                        "seconds"
                                    );
                                }
                            }
                        }

                        if (!userInfo.isSkipOutro) {
                            return;
                        }

                        if (
                            state.playedSeconds >=
                                skipSettings.skipOutroStartTime &&
                            state.playedSeconds <= skipSettings.skipOutroEndTime
                        ) {
                            player.current.seekTo(
                                skipSettings.skipOutroEndTime,
                                "seconds"
                            );
                        }
                    }}
                    onStart={() => {
                        watchStore.updateUserInfo({
                            ...userInfo,
                            lastViewedContentName: contentName,
                            lastViewedSeriesNumber: seriesNumber,
                        });
                    }}
                    onEnded={() => onNext()}
                    onError={(error, data) => console.log(error, data)}
                />
            </Box>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                }}
            >
                <Button
                    disabled={Number(seriesNumber) === 1}
                    variant="contained"
                    color="success"
                    onClick={onPrevious}
                    sx={{ width: "115px" }}
                >
                    Previous
                </Button>

                <Button
                    disabled={
                        Number(seriesNumber) ===
                        watchStore.availableSeries[contentName].length
                    }
                    variant="contained"
                    color="success"
                    onClick={onNext}
                    sx={{ width: "115px" }}
                >
                    Next
                </Button>
            </Box>

            <Box>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={userInfo.isSkipIntro}
                            onChange={(e, checked) => {
                                watchStore.updateUserInfo({
                                    ...userInfo,
                                    isSkipIntro: checked,
                                });
                            }}
                        />
                    }
                    label="Skip Intro?"
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={userInfo.isSkipOutro}
                            onChange={(e, checked) => {
                                watchStore.updateUserInfo({
                                    ...userInfo,
                                    isSkipOutro: checked,
                                });
                            }}
                        />
                    }
                    label="Skip Outro?"
                />
            </Box>
        </Box>
    ) : null;
});
