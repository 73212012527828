import { action, makeObservable, observable, runInAction } from "mobx";

import { AccountService } from "services";
import { StoreModel } from "stores";
import { LoginResponse } from "types";

export class AuthStore {
    private _accountService: AccountService;

    @observable public isAuthorized: boolean = false;
    @observable public userName: string;

    constructor(private _rootStore: StoreModel) {
        this._accountService = new AccountService(_rootStore.httpClient);

        makeObservable(this);
    }

    init = async () => {
        return await this.login();
    };

    @action
    login = async (): Promise<LoginResponse> => {
        try {
            const response = await this._accountService.login();

            runInAction(() => {
                this.userName = response.userName;
                this.isAuthorized = true;
            });

            return response;
        } catch (error) {
            throw error;
        }
    };

    signIn = async (userName: string, password: string): Promise<boolean> => {
        return await this._accountService.signIn(userName, password);
    };

    signUp = async (userName: string, password: string): Promise<boolean> => {
        return await this._accountService.register(userName, password);
    };

    updatePassword = async (oldPassword: string, newPassword: string) => {
        return await this._accountService.updatePassword(
            oldPassword,
            newPassword
        );
    };

    @action
    logout = async () => {
        try {
            await this._accountService.logout();

            runInAction(() => {
                this.isAuthorized = false;
            });
        } catch (error) {
            throw error;
        }
    };
}
