import { action, makeObservable, observable, runInAction } from 'mobx';
import { AxiosInstance } from 'axios';

import { AuthStore } from './AuthStore';
import { HttpClient } from 'api';
import { WatchStore } from './WatchStore';

export class StoreModel {
    @observable public isInitialized: boolean = false;

    public authStore: AuthStore;
    public watchStore: WatchStore;

    public httpClient: AxiosInstance;

    constructor() {
        this.httpClient = new HttpClient().http;

        this.authStore = new AuthStore(this);
        this.watchStore = new WatchStore(this);

        makeObservable(this);
    }

    @action
    init = async () => {
        await this.authStore.init();

        await this.watchStore.init();

        runInAction(() => {
            this.isInitialized = true;
        });
    };
}
