import { useNavigate, useParams } from "react-router-dom";
import { Box, Button } from "@mui/material";

import { useStores } from "hooks";
import { useEffect } from "react";
import { observer } from "mobx-react";

export const ContinueButton = observer(() => {
    const { watchStore } = useStores();
    const navigate = useNavigate();
    const { contentName } = useParams();
    const { userInfo } = watchStore;

    if (!contentName) {
        return null;
    }

    useEffect(() => {
        watchStore.getUserInfo();
    }, [watchStore]);

    return userInfo.lastViewedContentName === contentName ? (
        <Box
            sx={{
                paddingBottom: "25px",
                display: "flex",
                justifyContent: "center",
            }}
        >
            <Button
                variant="contained"
                color="warning"
                onClick={() => {
                    navigate(
                        `/watch/${contentName}/${userInfo.lastViewedSeriesNumber}`
                    );
                }}
            >
                {`Continue watch series ${userInfo.lastViewedSeriesNumber}`}
            </Button>
        </Box>
    ) : null;
});
