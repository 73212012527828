import { Box, Button, TextField, Typography } from "@mui/material";
import { useState } from "react";

import { useStores } from "hooks";

export const UpdatePasswordPage = () => {
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const { authStore } = useStores();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const oldPassword = formData.get("oldPassword")?.toString() ?? "";
        const newPassword = formData.get("newPassword")?.toString() ?? "";
        const newPasswordConfirm =
            formData.get("newPasswordConfirm")?.toString() ?? "";

        if (newPassword !== newPasswordConfirm) {
            setSuccessMessage("");
            setErrorMessage("New passwords must match");
            return;
        }

        const result = await authStore.updatePassword(oldPassword, newPassword);

        if (result) {
            setErrorMessage("");
            setSuccessMessage("Password updated successfully");
        } else {
            setErrorMessage("Incorrect old password");
            setSuccessMessage("");
        }
    };

    return (
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <Box
                sx={{
                    marginTop: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "450px",
                }}
            >
                <Typography component="h1" variant="h5">
                    Update Password
                </Typography>
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{ mt: 1 }}
                >
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="oldPassword"
                        label="Old Password"
                        name="oldPassword"
                        type="password"
                        autoComplete="current-password"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="newPassword"
                        label="New Password"
                        type="password"
                        id="newPassword"
                        autoComplete="new-password"
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="newPasswordConfirm"
                        label="New Password Confirm"
                        type="password"
                        id="newPasswordConfirm"
                        autoComplete="new-password"
                    />
                    <Box sx={{ height: "16px" }}>
                        <Typography color="red">{errorMessage}</Typography>
                    </Box>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Update Password
                    </Button>
                    <Box sx={{ height: "16px", textAlign: "center" }}>
                        <Typography color="green">{successMessage}</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
