import { action, makeObservable, observable } from "mobx";

import { WatchService } from "services";
import { StoreModel } from "stores";
import { AvailableSeries, SkipSettings, UserInfo } from "types";

export class WatchStore {
    private _watchService: WatchService;

    @observable public availableWatch: Array<string> = [];
    @observable public availableSeries: {
        [key: string]: Array<AvailableSeries>;
    } = {};
    @observable public skipSettings: SkipSettings = {} as SkipSettings;
    @observable public userInfo: UserInfo = {} as UserInfo;

    constructor(private _rootStore: StoreModel) {
        this._watchService = new WatchService(_rootStore.httpClient);

        makeObservable(this);
    }

    @action
    init = async () => {
        const result = await this._watchService.getAvailableWatch();

        this.availableWatch = result.available;
    };

    @action
    getAvailableSeries = async (contentName: string) => {
        if (!this.availableSeries[contentName]) {
            const result = await this._watchService.getAvailableSeries(
                contentName
            );

            this.availableSeries[contentName] = result.availableSeries;
        }

        return this.availableSeries[contentName];
    };

    getSeriesName = (contentName: string, seriesNumber: number | string) => {
        return this.availableSeries[contentName].find(
            (x) => x.id == seriesNumber
        )?.name;
    };

    @action
    getSkipSettings = async (
        contentName: string,
        seriesNumber: number | string
    ) => {
        try {
            const result = await this._watchService.getSkipSettings(
                contentName,
                seriesNumber
            );

            this.skipSettings = result;
        } catch {
            console.log("test");
            this.skipSettings = {
                skipIntroStartTime: 0,
                skipIntroEndTime: 0,
                skipOutroStartTime: Number.MAX_VALUE,
                skipOutroEndTime: Number.MAX_VALUE,
            };
        }
        return this.skipSettings;
    };

    @action
    getUserInfo = async () => {
        const result = await this._watchService.getUserInfo();

        this.userInfo = result;

        return this.userInfo;
    };

    @action
    updateUserInfo = async (userinfo: UserInfo) => {
        this.userInfo = userinfo;

        this._watchService.updateUserInfo(userinfo);

        return this.userInfo;
    };

    isReady = (contentName: string, seriesNumber: string | number) => {
        let result = true;
        if (!this.availableSeries[contentName]) {
            result = false;
        }

        if (!this.userInfo) {
            result = false;
        }

        return result;
    };
}
