import * as React from 'react';

import { StoreModel } from 'stores';
import { StoreContext } from 'providers';

type MapperType<T> = (rootStore: StoreModel) => T;

export const useStores = (): StoreModel => React.useContext(StoreContext);

export const useStoresWithMap = <T>(mapper: MapperType<T>): T =>
    mapper(React.useContext(StoreContext));
