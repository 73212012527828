import {
    Box,
    Avatar,
    Button,
    TextField,
    Link,
    Grid,
    Typography,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";

import { useStores } from "hooks";

export const RegisterPage = observer(() => {
    const { authStore } = useStores();
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        if (authStore.isAuthorized) {
            navigate("/");
        }
    }, [navigate, authStore]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const userName = formData.get("userName")?.toString() ?? "";
        const password = formData.get("password")?.toString() ?? "";
        const confirmPassword =
            formData.get("confirmPassword")?.toString() ?? "";

        if (password !== confirmPassword) {
            setErrorMessage("Passwords must match");
            return;
        }

        const result = await authStore.signUp(userName, password);

        if (result) {
            window.location.href = "/";
        } else {
            setErrorMessage("User with provided username already exists");
        }
    };

    return authStore.isAuthorized ? null : (
        <Box
            sx={{ display: "flex", justifyContent: "center", height: "100vh" }}
        >
            <Box
                sx={{
                    marginTop: 12,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "450px",
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{ mt: 1 }}
                >
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="userName"
                        label="User Name"
                        name="userName"
                        autoComplete="username"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="new-password"
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="confirmPassword"
                        label="Confirm Password"
                        type="password"
                        id="confirmPassword"
                        autoComplete="new-password"
                    />
                    <Box sx={{ height: "16px" }}>
                        <Typography color="red">{errorMessage}</Typography>
                    </Box>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign Up
                    </Button>
                    <Grid container>
                        <Grid item>
                            <Link
                                href="#"
                                variant="body2"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/Account/Login");
                                }}
                            >
                                {"Already have an account? Sign in"}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
});
