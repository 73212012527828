import axios, { AxiosInstance } from "axios";
import { createBrowserHistory } from "history";

const history = createBrowserHistory({ window });

export class HttpClient {
    private _http: AxiosInstance;

    constructor() {
        this._http = axios.create({});
        this.initializeInterceptors();
    }

    private initializeInterceptors() {
        this._http.interceptors.response.use(
            (originalResponse) => {
                return originalResponse;
            },
            (error) => {
                const { response } = error;

                // Client errors
                if (response && response.status >= 400) {
                    const params = response.config.params || {};
                    if (response.status === 401 && !params.__isRetryRequest) {
                        if (window.location.pathname !== "/Account/Register") {
                            history.push(response.headers.location);
                        }

                        return Promise.reject(response);
                    }

                    if (response.status === 400 && !params.__isRetryRequest) {
                        return Promise.resolve(response);
                    }
                    console.error(error);
                    return Promise.reject(response);
                }
            }
        );
    }

    public get http(): AxiosInstance {
        if (!this._http) {
            throw new Error("HttpClient is not initialized");
        }

        return this._http;
    }
}

export default HttpClient;
