import { Box, Button } from "@mui/material";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";

import { useStores } from "hooks";

export const Header = observer(() => {
    const { watchStore, authStore } = useStores();
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#f2f2f2",
                width: "100%",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                }}
            >
                <Box>
                    <Button
                        sx={{ color: "#000", fontWeight: 700 }}
                        onClick={() => {
                            navigate("/");
                        }}
                    >
                        Carbon Player
                    </Button>
                    {watchStore.availableWatch.map((el) => (
                        <Button
                            key={el}
                            sx={{ color: "#333" }}
                            onClick={() => {
                                navigate(`/series/${el}`);
                            }}
                        >
                            {el}
                        </Button>
                    ))}
                </Box>
                <Box>
                    <Button
                        sx={{ textTransform: "none" }}
                        color="primary"
                        onClick={() => navigate("/Account/Password")}
                    >
                        {authStore.userName}
                    </Button>
                    <Button
                        sx={{ textTransform: "none" }}
                        color="primary"
                        onClick={() => {
                            navigate("/Account/Logout");
                        }}
                    >
                        Logout
                    </Button>
                </Box>
            </Box>
        </Box>
    );
});
