import { AxiosInstance } from "axios";

import {
    AvailableContentResponse,
    AvailableSeriesResponse,
    SkipSettings,
    UserInfo,
} from "types";

export class WatchService {
    constructor(private httpClient: AxiosInstance) {}

    getAvailableWatch = async () => {
        const response = await this.httpClient.get<AvailableContentResponse>(
            "/api/watch/available/content"
        );
        return response?.data;
    };

    getAvailableSeries = async (contentName: string) => {
        const response = await this.httpClient.get<AvailableSeriesResponse>(
            `/api/watch/available/series?contentName=${contentName}`
        );
        return response?.data;
    };

    getSkipSettings = async (
        contentName: string,
        seriesNumber: number | string
    ) => {
        const response = await this.httpClient.get<SkipSettings>(
            `/api/watch/skipSettings?contentName=${contentName}&seriesNumber=${seriesNumber}`
        );
        return response?.data;
    };

    getUserInfo = async () => {
        const response = await this.httpClient.get<UserInfo>(
            `/api/account/info`
        );
        return response?.data;
    };

    updateUserInfo = async (userInfo: UserInfo) => {
        const response = await this.httpClient.put<UserInfo>(
            `/api/account/info`,
            userInfo
        );
        return response?.data;
    };
}
