import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { Box, Button } from "@mui/material";
import { useEffect } from "react";

import { useStores } from "hooks";
import { ContinueButton } from "components";

export const SeriesPage = observer(() => {
    const navigate = useNavigate();
    const { watchStore } = useStores();
    const { contentName } = useParams();

    if (!contentName) {
        return null;
    }

    const { availableSeries } = watchStore;

    useEffect(() => {
        watchStore.getAvailableSeries(contentName);
    }, [watchStore, contentName]);

    return contentName in availableSeries ? (
        <Box padding="25px 0px 25px 0px">
            <ContinueButton />

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "30px",
                    flexWrap: "wrap",
                    justifyContent: "center",
                }}
            >
                {availableSeries[contentName].map((el) => {
                    return (
                        <Button
                            sx={{ width: "115px" }}
                            key={el.id}
                            variant="contained"
                            color="success"
                            onClick={() => {
                                navigate(`/watch/${contentName}/${el.id}`);
                            }}
                        >
                            {`Series ${el.id}`}
                        </Button>
                    );
                })}
            </Box>
        </Box>
    ) : null;
});
