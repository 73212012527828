import React from 'react';

import { StoreModel } from 'stores';

export const StoreContext = React.createContext<StoreModel>({} as StoreModel);

export type StoreProviderPropsType = {
    store: StoreModel;
    children: JSX.Element;
};

export const StoreProvider: React.FC<StoreProviderPropsType> = ({
    children,
    store,
}) => <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
