// import { useRef } from 'react';
// import ReactPlayer from 'react-player/lazy';
import { useRoutes } from "react-router-dom";

import routes from "router";

export const App = () => {
    const content = useRoutes(routes);

    return <>{content}</>;
};
