import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";

import { App } from "./App";
import { StoreModel } from "stores";
import { Loading } from "components";
import { StoreProvider } from "providers";

(async function main() {
    try {
        const root = ReactDOM.createRoot(
            document.getElementById("root") as HTMLElement
        );

        const store = new StoreModel();

        root.render(<Loading />);

        try {
            await store.init();
        } catch {}

        root.render(
            <StoreProvider store={store}>
                <BrowserRouter>
                    <CssBaseline />
                    <App />
                </BrowserRouter>
            </StoreProvider>
        );
    } catch (error) {
        console.log("Error running App", error);
    }
})();
