import { useEffect } from "react";

import { useStores } from "hooks";

export const LogoutPage = () => {
    const { authStore } = useStores();

    useEffect(() => {
        async function logout() {
            await authStore.logout();
            window.location.href = "/";
        }

        logout();
    }, [authStore]);

    return <></>;
};
